import React, { useState } from "react"
import { PropTypes } from "prop-types"
import { Link, navigate } from "gatsby"
import { useAuth } from "../../context/auth-context"
import { setTokenData } from "../../utils/auth"
import { isBrowser } from "../../utils/browser"
import Button from "../button"

function LoginForm({ formContent }) {
  const { login } = useAuth()
  const [isLoading, setIsLoading] = useState(false)

  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  })

  const [error, setError] = useState(null)

  const handleChange = ({ target }) => {
    setCredentials({
      ...credentials,
      [target.name]: target.value,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setIsLoading(true)
    login(credentials.username, credentials.password)
      .then((res) => {
        if (
          res?.data?.login?.user?.requires_email_verification &&
          !res?.data?.login?.user?.is_email_verified
        ) {
          navigate("/email-verify")
          return null
        }

        if (res?.data?.login?.user?.has_mfa) {
          navigate("/mfa")
          return null
        }

        navigate("/app/dashboard")
      })
      .catch((error) => {
        setError(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const goBack = (event) => {
    event.preventDefault()
    window.history.back()
  }

  if (isBrowser()) {
    window.addEventListener("message", function (event) {
      // if event origin is from portal.lantern and it contains registration payload
      if (event.origin === process.env.GATSBY_LANTERN_PORTAL_URL) {
        const payload = JSON.parse(event.data)
        if (payload.tokens) {
          // then set the user up
          setTokenData(payload.tokens)
        } else {
          throw Error("Registration Unsuccessful")
        }
      }
    })
  }

  return (
    <form onSubmit={handleSubmit} className="lg:px-8 pt-6 lg:pb-8">
      <div className="w-full">
        <input
          onChange={handleChange}
          className="default-form-field w-full"
          id="username"
          name="username"
          type="text"
          placeholder={formContent.email_input_placeholder}
          aria-label="Username"
          autoComplete="email"
        />
      </div>
      <div className="w-full">
        <input
          onChange={handleChange}
          className="default-form-field w-full"
          id="password"
          name="password"
          type="password"
          placeholder={formContent.password_input_placeholder}
          aria-label="Password"
          autoComplete="current-password"
        />
      </div>
      {error && (
          <small className="text-error">
            These credentials do not match our records. We recommend resetting your password if you continue to receive this message.
          </small>
        )}
      <div>
        <Link
          className="body-text block underline text-primary hover:text-grey-darker my-4 mb-0"
          to="/forgot-password"
        >
          {formContent.forgot_password_link_text}
        </Link>
      </div>
      <div className="py-4 mx-auto">
        <Button
          buttonText={isLoading ? "Verifying account..." : "Submit"}
          buttonType="btn out-of-app -primary"
          disabled={isLoading}
          eventName={{
            eventDomain: "App",
            eventLocation: "Login",
            eventAction: "Click",
            eventActionName: "Submit",
          }}
          testId="button_id"
        />
        <div onClick={goBack} className="body-text py-3 block cursor-pointer">
          {formContent.back}
        </div>
      </div>
    </form>
  )
}

LoginForm.propTypes = {
  formContent: PropTypes.object,
}

export default LoginForm
