import React, { useEffect } from "react"
import { navigate, Link, graphql, useStaticQuery } from "gatsby"
import FullPageLayout from "../components/full-page-layout"
import LoginForm from "../components/auth/login-form"
import { useAuth } from "../context/auth-context"
import SEO from "../components/seo"
import { isBrowser } from "../utils/browser"
import { useLocation } from "@reach/router"
import { useAlert } from "react-alert"

function LoginPage() {
  const { isLoggedIn } = useAuth()
  const alert = useAlert()

  if (isLoggedIn()) {
    navigate(`/app/dashboard`)
  }

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const employeeRegistered = queryParams.get("employee-registered")

  useEffect(() => {
    if (isBrowser()) {
      if (employeeRegistered) {
        alert.success(`We've created your account.`)
      }
    }
  }, [])

  const { allPrismicLoginPage } = useStaticQuery(graphql`
    query LoginPageQuery {
      allPrismicLoginPage {
        edges {
          node {
            data {
              back
              email_input_placeholder
              forgot_password_link_text
              password_input_placeholder
              register_link_text
              right_image {
                alt
                url
              }
              submit_button_text
              subtitle_login
              title {
                html
                text
              }
              seo_keywords_login {
                html
                text
              }
              seo_title_login {
                html
                text
              }
              seo_description_login {
                html
                text
              }
            }
          }
        }
      }
    }
  `)

  const {
    title,
    subtitle_login,
    register_link_text,
    right_image,
    seo_keywords_login,
    seo_title_login,
    seo_description_login,
  } = allPrismicLoginPage.edges[0].node.data

  return (
    <div>
      <SEO
        keywords={[
          seo_keywords_login?.text ?? seo_keywords_login.text.split(","),
        ]}
        title={seo_title_login?.text}
        description={seo_description_login.text}
        excludeSiteMetadataTitle
      />
      <FullPageLayout rightImageUrl={right_image.url}>
        <div className="flex items-center pt-32 lg:pt-36">
          <div className="text-center w-full px-6 lg:px-16">
            <div className="w-full flex items-center">
              <div className="w-full">
                <h1 className="headline-text">{title.text}</h1>
                <p className="sub-headline-text text-center">
                  {subtitle_login}&nbsp;
                  <Link
                    to="/register"
                    className="font-bold text-primary hover:underline"
                  >
                    {register_link_text}
                  </Link>
                </p>
              </div>
            </div>
            <div className="mx-auto md:w-2/3">
              <LoginForm formContent={allPrismicLoginPage.edges[0].node.data} />
            </div>
          </div>
        </div>
      </FullPageLayout>
    </div>
  )
}

export default LoginPage
